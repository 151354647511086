<template>
  <div>
    <CRow class="justify-content-center">
      <CCol sm="4">
        <CCard>
          <CCardHeader>
            물리보안 통합플랫폼 시스템 연동성 검증
          </CCardHeader>
          <CCardBody>
            <el-form label-position="top">
              <el-form-item label="연동 시스템">
                <el-radio-group v-model="record.system" :disabled="loading">
                  <el-radio class="w-75 ml-4" label="cctv">영상보안 시스템</el-radio>
                  <el-radio class="w-75 ml-4" label="bio">바이오인식 기반 출입통제 시스템</el-radio>
                  <el-radio class="w-75 ml-4" label="iot">보안용 센서 시스템</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-divider />
            </el-form>
              <!-- <strong class="mb-4">환경설정</strong> -->
              <!-- <el-form-item label="사용자 인증 방식" class="mt-4">
                <el-radio-group v-model="record.auth">
                  <el-radio class="w-75 ml-4" label="digest">Digest Auth</el-radio>
                  <el-radio class="w-75 ml-4" label="token">Bearer Token</el-radio>
                  <el-radio class="w-75 ml-4" label="none">None</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="메시지 송수신" class="mb-1"></el-form-item>
            </el-form>
            <el-form label-width="25%">
              <el-form-item label="transProtocol" class="mb-1">
                <el-select v-model="record.trans" disabled>
                  <el-option label="REST_API" value="rest"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="timeOut(sec)">
                <el-input-number v-model="record.timeout" controls-position="right" :min="0" :max="60" :step="1"></el-input-number>
              </el-form-item>
            </el-form> -->
            <el-form label-position="top">
              <el-form-item label="연동 URL">
                <el-input placeholder="연동 URL을 입력하세요." v-model="record.url" :disabled="loading"></el-input>
              </el-form-item>
            </el-form>
          </CCardBody>
          <CCardFooter>
            <el-button type="primary" class="float-right" :loading="loading" @click="onStart">검증 시작</el-button>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol sm="4">
        <el-input ref="verification_result" type="textarea" :rows="30" readonly v-model="file_txt" placeholder="시험 결과"></el-input>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'KisaVerification',
  data() {
    return {
      loading: false,
      record: {
        system: 'cctv',
        // auth: 'token',
        // trans: "rest",
        // timeout: 5,
        url: null
      },

      file_txt: null,
    }
  },
  methods: {
    onStart() {
      if (!this.record.url) {
        this.$message({
          message: '연동 URL을 입력하세요.',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      axios.post('/api/kisa/verification/', this.record)
        .then(response => {
          let task_id = response.data.task_id;
          this.$message({
            message: '검증이 시작되었습니다.',
            type: 'success'
          });
          setTimeout(() => {
            this.checkResult(task_id);
          }, 1000);
        })
        .catch(() => {
          this.$message({
            message: '검증을 시작할 수 없습니다.',
            type: 'error'
          });
          this.loading = false;
        });
    },
    checkResult(task_id) {
      axios.get(`/api/async/result/?task_id=${task_id}`)
        .then(response => {
          let progress = response.data.progress;
          let file_txt = response.data.file_txt;
          if (!progress.done) {
            setTimeout(() => {
              this.checkResult(task_id);
            }, 1000);
          } else {
            this.loading = false;
            file_txt += "\n완료되었습니다."
          }
          this.file_txt = file_txt;
          this.$nextTick(() => {
            const textarea = this.$refs.verification_result.$el.querySelector('.el-textarea__inner');
            textarea.scrollTop = textarea.scrollHeight;
          });
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: '서버와 통신할 수 없습니다.',
            type: 'error'
          });
        })
    }
  }
}
</script>
